import React from "react";
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => (
    <div className="video-responsive" style={responsiveStyles}>
        <iframe
            width="853"
            height="480"
            style={iframeStyles}
            src={`https://www.youtube-nocookie.com/embed/${embedId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
        />
    </div>
);

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
};

const responsiveStyles = {
    overflow: 'hidden',
    height: '50vh',
    paddingBottom: 24
}

const iframeStyles = {
    height: '50vh',
    width: '50vw',
}

export default YoutubeEmbed;