import Heading from '../components/Heading.js';
import Section from '../components/Section.js';
import Hero from '../components/Hero.js';
import YoutubeEmbed from '../components/YoutubeEmbed';

const Impact = () => {
    return (
        <div className='content'>
            <Hero tagline='Impact' image='./hero.jpg' />
            <Section>

                <Heading>Impact</Heading>

                <p>
                    Proin quis ultrices urna. Cras elementum, justo ac molestie tincidunt, diam arcu pellentesque sapien, ut volutpat nunc ante eu eros. Nulla facilisi. Donec consequat a ipsum ac cursus. Nullam venenatis pretium tincidunt. Aliquam erat volutpat. Donec interdum mauris sed ornare finibus. Aenean quis arcu a turpis suscipit fringilla quis a nibh. Donec non congue urna. Nulla quis auctor massa, eget efficitur justo. Sed erat nunc, tempus at lectus nec, viverra porttitor ante. Sed vulputate consequat massa. In hac habitasse platea dictumst. Mauris iaculis faucibus tortor vitae rhoncus. Donec sem urna, ultrices eget ex ac, rutrum imperdiet ligula.
                </p>

                <br />

                <YoutubeEmbed embedId="kvM5gabHzdQ" />

            </Section>
        </div>
    );
}

export default Impact;