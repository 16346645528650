import Heading from '../components/Heading.js';
import Section from '../components/Section.js'
import Hero from '../components/Hero.js';
import YoutubeEmbed from '../components/YoutubeEmbed';

const What = () => {
    return (
        <div className='content'>
            <Hero tagline='What' image='./hero.jpg' />
            <Section>

                <Heading>What</Heading>

                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam molestie turpis eget convallis dictum. Donec accumsan libero a enim volutpat, eget suscipit elit luctus. Nullam ornare felis et lobortis mattis. Aenean facilisis magna ut arcu pretium, non congue purus vestibulum. Vestibulum at lacus enim. Aenean id consequat est. Nunc maximus imperdiet lacinia. Pellentesque sem mauris, varius ut lorem vel, eleifend commodo magna. Aliquam gravida purus vitae augue ornare, id efficitur justo blandit. Morbi imperdiet, velit quis porta pellentesque, libero nisi mollis quam, eu tincidunt mi justo at nunc. Ut aliquet rhoncus sapien sed semper. Sed quis metus leo. In non commodo quam.
                </p>

                <br />

                <YoutubeEmbed embedId="pN0dkjp1deQ" />

            </Section>
        </div>
    );
}

export default What;