import hero from '../hero.jpg';

const Hero = ({ tagline }) => {
    return (
        <div className="hero" style={heroStyles}>
            <h1 style={tagStyles}>{ tagline }</h1>
        </div>
    );
}

const heroStyles = {
    background: `linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(${hero})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: 360,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

const tagStyles = {
    margin: 0,
    color: 'white'
}

export default Hero;