import Navbar from './components/Navbar.js'
import What from './sections/What.js'
import Why from './sections/Why.js'
import Impact from './sections/Impact.js'
import {
  Route, 
  Routes
} from "react-router-dom";
import Heading from './components/Heading.js';
import Section from './components/Section.js';

export default function App() {
  return (
    <div className='App'>
      <Navbar title='App' />

      <Routes>
        <Route path="/" element={<What />} />
        <Route path="/why" element={<Why />} />
        <Route path="/impact" element={<Impact />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
}

const NoMatch = () => {
  return (
    <Section>
      <Heading>404</Heading>

      <p>
        Could not find the content you were searching for.
      </p>
      
    </Section>
  );
}