const Heading = (props) => {
    return (
        <h1 style={headingStyles}>
            {props.children}
        </h1>
    );
}

const headingStyles = {
    margin: 0
}

export default Heading;