const Section = (props) => {
    return (
        <section style={sectionStyles}>
            {props.children}
        </section>
    );
}

const sectionStyles = {
    padding: 24,
    margin: 0,
    height: 450
}

const paragraphStyles = {
    margin: 0
}

export default Section;