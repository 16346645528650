import React from 'react';
import { Link } from "react-router-dom";

const Navbar = ({ title }) => {
  return (
    <div className="navbar" style={navbarStyles}>
      <Link to="/"><h1 style={brandStyles}>{title}</h1></Link>
      <div style={links}>
        <Link to="/" style={linkStyles}>What</Link>
        <Link to="/why" style={linkStyles}>Why</Link>
        <Link to="/impact" style={linkStyles}>Impact</Link>
      </div>
    </div>
  );
}

Navbar.defaultProps = {
  title: 'App',
}

const linkStyles = {
  textDecoration: 'none',
  color: 'white',
  paddingLeft: 24,
  fontSize: 17,
}

const navbarStyles = {
  backgroundColor: 'black',
  overflow: 'hidden',
  padding: "14px 24px 14px 24px",
}

const brandStyles = {
  color: 'white',
  float: 'left',
  fontSize: 17,
  margin: 0
}

const links = {
  float: 'right',
}

export default Navbar;