import Heading from '../components/Heading.js';
import Section from '../components/Section.js';
import Hero from '../components/Hero.js';
import YoutubeEmbed from '../components/YoutubeEmbed';

const Why = () => {
    return (
        <div className='content'>
            <Hero tagline='Why' image='./hero.jpg' />
            <Section>

                <Heading>Why</Heading>

                <p>
                    Ut eget ipsum vel elit tristique placerat non vitae mi. Curabitur vel gravida turpis, nec sollicitudin massa. Aliquam rutrum pretium ex non pretium. Etiam mollis nunc in neque pellentesque semper. Sed hendrerit blandit lectus id cursus. Suspendisse ullamcorper sem nisi, eget semper orci hendrerit in. Donec ac sem non magna venenatis blandit. Morbi elementum nisi non ante rhoncus viverra. Vivamus at augue at elit dictum euismod vitae eget felis. Quisque orci mi, porttitor ut velit quis, faucibus condimentum diam. Donec ac feugiat lectus, nec condimentum est. Cras a odio dictum, bibendum ante nec, elementum sem.
                </p>

                <br />

                <YoutubeEmbed embedId="WkdtmT8A2iY" />

            </Section>

        </div>
    );
}

export default Why;